import { useContext, useEffect } from "react";
import { useStrapiObjectClass } from "../hooks/useStrapiObject";
import { useUser } from "../hooks/useUser";
import DataGridStatistika from "../Components/DataGridStatistika";
import { useState } from "react";
import { TaskContext } from "../AppStates";
import { MakeTime, TikLaikas } from "../Components/MainModal/Price";
import { useTranslation } from "react-i18next";
import DefaultModal from '../Components/Modal';
import { TextField } from "@mui/material";
import CustomButton from "../Components/Mui/CustomButton";
import ArchiveModal from "../Components/ModalArchive";
import ModalSeni from "../Components/ModalSeni";

const Statistika = () => {
    const { t } = useTranslation()
    const { user } = useContext(TaskContext)
    const { data: darbai, getSingle, getAll, update } = useStrapiObjectClass({ name: 'darbai' })
    const { data: seni, getSingle: getSingleOld, getAll: getSeni, update: updateOld } = useStrapiObjectClass({ name: 'seni-d' })
    const { users: useriai, getAllUsers } = useUser()
    const [tvarkyta, setTvarkya] = useState({})
    const [sanaudosModal, setSanaudosModal] = useState(false)
    const [medziaguModal, setMedziaguModal] = useState(false)
    const [id, setId] = useState(null)
    const [modal, setModal] = useState(false)
    const [modal2, setModal2] = useState(false)
    const [type, setType] = useState(false)
    const [info, setInfo] = useState({})
    const [senas, setSenas] = useState(false)
    useEffect(() => {
        getAllUsers();
        getAll();
        getSeni();
    }, [getAllUsers, getAll, getSeni]);
    useEffect(() => {
        if (id)
            if (type) {
                getSingleOld(id, setInfo)
            }
            else {
                getSingle(id, setInfo)
            }
    }, [id])
    const r = () => {
        getAll();
        getSeni();
    }
    useEffect(() => { redoData(darbai.concat(seni), setTvarkya); }, [darbai, seni, useriai])
    const getVardas = (element) => {
        let id = element.attributes.darbuotojoId
        let vardas = ''
        useriai.map(darbuotojas => {
            if (darbuotojas.id == id) {
                vardas = darbuotojas.Vardas + ' ' + darbuotojas.Pavarde
                return vardas
            }
        })
        return vardas
    }

    const redoData = async (darbai, setter) => {//data for dataGrid
        var arr = []
        darbai.forEach(async (element) => {
            let x = 0
            let time
            let medziaguKaina = 0
            let medziaguPirkimas = 0
            let visas = 0
            if (element.attributes?.pradirbtasLaikas?.pradėta && element.attributes.pradirbtasLaikas.pabaigta) {
                x = TikLaikas(new Date(element.attributes.pradirbtasLaikas.pradėta), new Date(element.attributes.pradirbtasLaikas.pabaigta), element.attributes.medziagos, t('daugiklis'), t('iskvietimoMokestis'))
                x = (x / 1.21)
                time = MakeTime(new Date(element.attributes.pradirbtasLaikas.pradėta), new Date(element.attributes.pradirbtasLaikas.pabaigta))
                const { hours, minutes, seconds } = convertMillisecondsToHoursMinutes(time)
                if (!isNaN(hours) && !isNaN(minutes) && !isNaN(seconds))
                    // return hours + ":" + minutes
                    var galutinisLaikas = hours + ":" + minutes
            }
            if (element.attributes.medziagos) {
                medziaguKaina = Math.round(medziaguSkaiciuokleBePvm(element.attributes.medziagos) * 100) / 100
            }
            if (element.attributes.medziagos) {
                medziaguPirkimas = Math.round(element.attributes?.medziaguKaina * 100) / 100
            }
            visas = Math.round((x + medziaguKaina) * 100) / 100

            if (!user.is_admin && !user.is_buhaltere) {
                if (element.attributes.darbuotojoId != user.id) {
                    return
                }
            }
            let vardas = getVardas(element) || ''
            if (!element.attributes.data) {
                return
            }
            arr.push(
                {
                    id: element.attributes.IDNr || element.id + (element.attributes.seni ? '-' + (element.attributes.seni.length + 1) : '-1'),
                    data: element.attributes.data[0] ? element.attributes.data[0] : '',
                    sanaudos: Math.round(element.attributes?.sanaudos * 100) / 100,
                    medziaguKainaPirkimas: medziaguPirkimas,
                    kategorija: element.attributes.kategorija,
                    darbuotojoId: vardas,
                    time: galutinisLaikas,
                    timeCount: time,
                    visaKaina: visas,
                    darboPajamos: Math.round((x) * 100) / 100 || 0,
                    medziaguKaina: Math.round((medziaguKaina) * 100) / 100 || 0,
                    statusas: element.attributes.statusas,
                    Idnr: element.attributes.IDNr,
                    darboPelnas: Math.round((visas - medziaguPirkimas - element.attributes.sanaudos) * 100) / 100,
                    modal: (id) => {
                        if (element.attributes.IDNr) { setModal(true); setSenas(true) } else { setModal(true); setSenas(false) }
                        setId(element.id);
                    },
                    sanaudosModal: (id, nr) => { setSanaudosModal(true); setId(element.id); setType(nr ? true : false) },
                    ivedamosMedziagosModal: (id, nr) => { setMedziaguModal(true); setId(element.id); setType(nr ? true : false) }
                }
            )
        });
        setter(arr)
        return arr
    }
    const handleEvent = () => { }
    if (Object.keys(tvarkyta).length == 0) {
        return ('')
    }
    return (
        <div className="container">
            <div className='archyvas mainarch'>
                <DataGridStatistika
                    data={tvarkyta} onRowClick={handleEvent}
                />
            </div>
            <DefaultModal
                classnames={'modal3'}
                isOpen={sanaudosModal}
                requestClose={() => { setSanaudosModal(false); }}
                element={<Sanaudos
                    info={info}
                    id={id}
                    update={type ? updateOld : update}
                    r={r}
                    setInfo={setInfo}
                    setSanaudosModal={setSanaudosModal}
                />}
            />
            <DefaultModal
                classnames={'modal3'}
                isOpen={medziaguModal}
                requestClose={() => { setMedziaguModal(false); }}
                element={<MedziaguKaina
                    info={info}
                    id={id}
                    update={type ? updateOld : update}
                    r={r}
                    setInfo={setInfo}
                    setSanaudosModal={setMedziaguModal}
                />}
            />
            {modal ?
                <ArchiveModal id={id} setIsOpen={setModal} modalIsOpen={modal} r={r} setR={() => r} senasOn={senas} />
                :
                ''
            }

        </div >
    )
}
export default Statistika;

const MedziaguKaina = ({ id, update, info, setInfo, r, setSanaudosModal }) => {
    const { t } = useTranslation()
    return (
        <div>
            <TextField
                color="primary"
                variant="outlined"
                type="text"
                label={t('medziaguKaina')}
                size="small"
                margin="none"
                multiline
                value={info.attributes?.medziaguKaina ? info.attributes?.medziaguKaina : ''}
                onChange={(value) => { setInfo({ ...info, attributes: { medziaguKaina: value.target.value } }) }}
            />
            <div>

                <CustomButton
                    text={'issaugoti'}
                    onClick={() => {
                        update(id, info.attributes).then(() => { r(); setSanaudosModal(false) })
                    }}
                />
            </div>
        </div>
    )
}
const Sanaudos = ({ id, update, info, setInfo, r, setSanaudosModal }) => {
    const { t } = useTranslation()
    return (
        <div>
            <TextField
                color="primary"
                variant="outlined"
                type="text"
                label={t('sanaudos')}
                size="small"
                margin="none"
                multiline
                value={info.attributes?.sanaudos ? info.attributes?.sanaudos : ''}
                onChange={(value) => { setInfo({ ...info, attributes: { sanaudos: value.target.value } }) }}
            />
            <div>

                <CustomButton
                    text={'issaugoti'}
                    onClick={() => {
                        update(id, info.attributes).then(() => { r(); setSanaudosModal(false) })
                    }}
                />
            </div>
        </div>
    )
}

const makeDate = (date) => {
    return new Date(date).toISOString().split('T')[0].replace('-', '.').replace('-', '.')
}
function convertMillisecondsToHoursMinutes(milliseconds) {
    const hours = Math.floor(milliseconds / (1000 * 60 * 60));
    const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);
    return { hours, minutes, seconds };
}
export const medziaguSkaiciuokleBePvm = (medziagos = null) => {
    var suma = 0
    if (medziagos && Object.keys(medziagos).length > 0) {
        Object.keys(medziagos).map(e => {
            suma += parseFloat((medziagos[e].kaina / 1.21) * medziagos[e].kiekis)
        })
        return suma.toFixed(2)
    } else {
        return 0
    }
}