import './css/Modal.css';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import React, { useContext, useEffect, useState, } from 'react';
import { TaskContext } from '../AppStates';
import './css/calendar.css'
import ImportAll from '../functions/importAll';
import { Loader } from './Loader';
import { useStrapiObjectClass } from '../hooks/useStrapiObject';
import CustomSelect from './Mui/CustomSelect';


const UserPickerSelect = ({ info, deploy, id, tag, close, darbuotojas, r, disabled }) => {
    const { path, token } = useContext(TaskContext)
    const { t } = useTranslation()
    const [, setInfo] = useState(info || [])
    const [, setDeploy] = useState(deploy)
    const [, setId] = useState(id)
    const [darbo, setDarbo] = useState([])
    const [grafikai, setGrafikai] = useState([])
    const [yraGalimu, setYraGalimu] = useState(false)
    const [ats, setAts] = useState({})
    const [tinkaGrafikai, setTinkaGrafikai] = useState({})
    const { update } = useStrapiObjectClass({ name: 'darbai' })

    const getGrafikai = async () => {
        let kategorija = t('kategorija').indexOf(deploy?.kategorija) + ''
        ImportAll(path, token, 'darbo-dienos/?filters[Kategorija][$contains]=' + kategorija, setGrafikai, 'users');
    }
    const getDarbai = async () => {
        let data = ''
        if (deploy && deploy?.data && deploy?.data[0])
            data = deploy?.data[0].split('T')[0] || null
        let laikas = deploy.Laikas
        await ImportAll(path, token, 'darbai/?filters[data][$containsi]=' + data + '&[Laikas][&containsi]=' + laikas, setDarbo);
    }
    const updeitas = async (id, info, userId) => {
        var save = {}
        if (deploy.statusas == t('nepriskirtas_pratestas')) {
            save.statusas = t('priskirtas_tesiamas')
        }
        else if (deploy.statusas == t('samata')) {
            save.statusas = t('samata')
        }
        else { save.statusas = t('priskirtas') }
        await update(id, { darbuotojoId: userId, statusas: save.statusas }, setDeploy);
        r()

    }
    const updateUser = {}
    useEffect(() => {
        getGrafikai()
    }, [info])


    useEffect(() => {
        if (deploy?.data?.length > 0)
            var data = deploy?.data[0].split('T')[0]
        var tinka
        var suskaiciuota
        if (grafikai.data) {
            if (grafikai.data.length > 0) {
                tinka = grafikai.data.map(e => {
                    let datos = Object.keys(e.attributes.datos)
                    if (datos.indexOf(data) != -1 &&
                        e.attributes.datos[data].laikas.indexOf(deploy.Laikas[0]) != -1) {
                        return e.attributes.userId
                    }
                })
                if (info.length > 0)
                    suskaiciuota = info.map(e => {
                        if (tinka.indexOf(e.id + '') != -1) {
                            return e
                        }
                    }).filter(item => item !== undefined)
                setTinkaGrafikai(suskaiciuota)
                getDarbai()
            }
        }
    }, [grafikai])
    useEffect(() => {
        var negalima = []
        let skaiciuojami = []
        if (darbo.length > 0) {
            darbo.map(e => {
                if (skaiciuojami.indexOf(e.attributes.darbuotojoId) != -1) {
                    negalima.push(e.attributes.darbuotojoId)
                }
                else if (e.attributes.darbuotojoId != null && e.attributes.darbuotojoId != -1) {
                    skaiciuojami.push(e.attributes.darbuotojoId)
                }
            })

            let galimi = []
            if (tinkaGrafikai)
                galimi = tinkaGrafikai.map(e => { if (negalima.indexOf(e.id) == -1) { return e } }).filter(item => item !== undefined)
            if (galimi.length != 0) {
                setAts(galimi)
                setYraGalimu(true)
            }
        }

    }, [darbo])
    if (!info) {
        return <Loader />
    }
    // return 'div'
    return (
        <div className=''>
            {yraGalimu == true ?
                <div className='userPicker'>
                    <div>
                        <CustomSelect
                            disabled={disabled}
                            options={ats.map(item => {
                                return item.id
                            })}
                            optionsText={ats.map(item => {
                                return item.Vardas + ' ' + item.Pavarde
                            })}
                            label={t('priskirtiDarbuotoja')}
                            current={darbuotojas || ''}
                            set={(e) => {
                                console.log(e)
                                updeitas(id, deploy, e)
                            }}
                        />
                    </div>
                </div>
                : ''}
        </div>
    )
}
export default UserPickerSelect